import React from 'react'
import { generateOptions } from '../../lib/utils/forms'
import {
    PATTERN_EMAIL,
    PATTERN_NO_NUMERIC_STRING,
    PATTERN_STRING,
} from '../../lib/utils/forms/patterns'

// REQUEST DEMO FORM

export const getRequestDemoFormFields = (product: string) => [
    {
        name: 'First Name',
        label: 'First Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Last Name',
        label: 'Last Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Email',
        label: 'Email',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_EMAIL, 30),
    },
    {
        name: 'Company',
        label: 'Company',
        type: 'input' as const,
        validation: generateOptions(true, PATTERN_STRING, 30),
    },
    {
        name: 'Trial Source',
        label: 'Describe your data streaming infrastructure and use case',
        type: 'textarea' as const,
        defaultValue: product === 'k2k' ? 'Kafka to Kafka replication.' : '',
    },
]

export const requestDemoFormPardotHandler = 'https://go.pardot.com/l/877242/2024-10-30/t227j8'
export const requestK2KDemoFormPardotHandler = 'https://go.pardot.com/l/877242/2025-03-05/t32g5n'

export const requestDemoFormTextBlock = [
    {
        pretitle: 'Lenses Demo',
        title: 'Book a guided tour',
        description: (
            <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                <li>Schedule a 20-minute demo</li>
                <li>Receive a trial key to install limited features of Lenses on your clusters</li>
                <li>
                    <a href='/product/pricing' title='buy'>
                        Buy
                    </a>{' '}
                    to extend. Perfect for large-scale Kafka deployments.
                </li>
            </ul>
        ),
    },
]

export const requestK2KDemoFormTextBlock = [
    {
        pretitle: 'LENSES K2K',
        title: 'Sign up for the preview',
        description: (
            <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                <li>See what we’re building</li>
                <li>Shape it with us</li>
                <li>Start replicating.</li>
            </ul>
        ),
    },
]

export const requestDemoFormSubmitButtonLabel = 'Schedule now'
export const requestK2KDemoFormSubmitButtonLabel = 'Register now'

export const requestDemoFormSuccessTitle = 'Success! '

export const requestDemoFormSuccessDescription = 'Now schedule a demo slot:'

export const requestDemoFormSuccessButtonLabel = 'Book a demo'
export const requestK2KDemoFormSuccessButtonLabel = 'See calendar'

export const requestDemoFormSuccessButtonLink =
    'https://lenses.chilipiper.com/book/me/sandra-czajkowska1?type=my-meeting'
export const requestK2KDemoFormSuccessButtonLink =
    'https://lenses.chilipiper.com/book/me/sandra-czajkowska1?type=my-meeting'

export const requestDemoFormErrorTitle = 'There has been an error'
export const requestDemoFormErrorDescription =
    'There has been an error when sending your request. Please try again.'

export const requestDemoFormErrorButtonLabel = 'Try again'
export const requestDemoFormErrorButtonLink = '/request-demo'
